<template>
	<div style="background: #f1f2f6; height: 100%;">
		<van-nav-bar title="图书电商" left-arrow fixed placeholder :border="false" @click-left="onClickLeft" />

		<div class="container">
			<img style="width: 25.6%;"
				:src="item.img"
				alt="">
			<p style="font-size: 18px; color: #333; font-size: font-weight: bold;;">{{item.title}}</p>
			<!-- <p style="font-size: 12px; color: #999;">罗贯中</p> -->
			<p style="font-size: 12px; color: #999;">{{item.reading}}</p>
		</div>

		<p style="font-weight: bold; padding: 0 10px; margin-bottom: 10px;">简介</p>
		<p style="padding: 0 10px; color: #999; font-size: 12px;">
			{{item.sub2}}</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				item:null
			}
		},
		created() {
			this.item = this.$route.query.obj
		},
		methods:{
			onClickLeft() {
			
				let u = navigator.userAgent;
				let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
				let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
				if (isIOS) {
					window.webkit.messageHandlers.close.postMessage("up");
				}
				if (isAndroid) {
					window.android.close();
				}
				// console.log('返回');
				// this.$router.go(-1)
			
			},
		}

	}
</script>

<style lang="less" scoped>
	/deep/ .van-nav-bar {
		background: #f1f2f6;
	}

	/deep/ .van-nav-bar__left {
		padding: 0 5px;
	}

	.container {
		background: #F6F6F6;
		text-align: center;
		padding: 10px 0;
		margin-bottom: 30px;
	}
</style>
